.datepicker-bill .input-date {
    border: none !important;
    max-width: 239px;
    position: relative;
    width: 239px;
}

.input-date {
    max-width: 180px;
}
