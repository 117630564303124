.container-department {
    /* width: calc(100% - 56px);
    height: calc(100% - 120px); */
    width: 100%;
    /* min-height: 100%; */
    background-color: #E3E6E8;
    padding: 28px;
    /* overflow: hidden; */
}

.department-content {
    /* width: calc(100% - 56px);
    height: calc(100% - 56px); */
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    border-radius: 12px;
    padding: 28px;
}
.department-row {
    height: 100%;
    display: flex !important;
    flex-direction: row;
    align-items: center;
}

.department-row img{
    padding-right: 34px;
}
.table-departments {
    width: 100%;
    height: 92%;
    /* overflow-y: auto; */
}
