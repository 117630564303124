.input-area {
    border-radius: 8px !important;
}

.workinglog-dropdown .ant-select-selector {
    border-radius: 8px !important;
    padding: 3px 12px !important;
    height: unset !important;
}

.workinglog-create .ant-modal-header {
    border-radius: 12px !important;
}

.workinglog-create .ant-modal-content {
    border-radius: 12px !important;
}

.button-plus-wrp {
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #215493;
    padding-left: 5px;
}

.custom-label {
    font-size: 14px;
    color: #344054;
}
.maxw-full {
    max-width: 100% !important;
}
.list-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
}
.list-icon .view-ic {
    background-color: #215493;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.list-icon .edit-ic {
    background-color: #215493;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.list-icon .delete-ic {
    background-color: #ff5045;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
