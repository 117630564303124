@import "../CommonStyle.scss";
.line-header {
  position: sticky;
  top: 0px;
  z-index: 112;
  margin: 0px -15px;
  height: 56px;
  .lg {
    font-weight: bold;
    font-size: 21px;
    line-height: 54px;
  }
}
.url-input {
  border-bottom-right-radius: 0!important;
  border-right: 0;
  border-top-right-radius: 0!important;
  margin: 0;
}
.send-button {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin: 0;
}
.line-layout {
  .selected-file-name {
    font-size: 14px;
  }
}
.top-page-wrapper {
  height: 100vh;
  width: 100%;
  background: #fff;
  .top-page {
    padding: 0;

    .header {
      background: #F2F2F2;
      padding: 8px 16px;
    }

    .content {
      padding: 50px 28px;

      .timestamps {
        font-size: 12px;
        font-weight: bold;
        color: #ADBAC9;
        text-align: center;
        margin-bottom: 12px;
      }

      .message-content {
        margin-bottom: 24px;

        .logo {
          width: 45px;
          height: 45px;
          margin-right: 16px;
        }

        .message {
          background: #F5FBFD;
          border-radius: 0 12px 12px;
          min-width: 220px;
          color: #0E262D;
          font-size: 12px;
          font-weight: bold;
          padding: 8px;
        }
      }
    }

    .footer {
      height: 83px;
      border-top: solid 2px #DEDEDE;
      padding: 12px 20px;
    }

    .top-page-menu {
      margin-right: auto;
      margin-left: auto;
      flex-direction: initial;
      transition: .3s linear;

      .menu-btn {
        background: #3ACE01;
        color: #FFFFFF;
        padding: 25px 0;
        text-align: center;
        border: solid 1px #fff;
        cursor: pointer;
      }
    }

    .hidden {
      opacity: 0;
    }
  }
}
