.container-position {
    width: calc(100% - 56px);
    height: calc(100% - 120px);
    /* height: 100%; */
    background-color: #E3E6E8;
    padding: 28px
}

.position-content {
    width: calc(100% - 56px);
    height: calc(100% - 56px);
    background: #FFFFFF;
    border-radius: 12px;
    padding: 28px;
    overflow: hidden;
}
