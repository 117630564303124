.container-project2 {
    /* width: calc(100% - 56px);
    height: calc(100% - 120px); */
    height: 100%;
    width: 100%;
    background-color: #E3E6E8;
    padding: 28px;
    overflow: hidden;
}

.project-content-border {
    /* width: calc(100% - 56px);
    height: calc(100% - 56px); */
    height: 100%;
    width: 100%;
    background: #FFFFFF;
    border-radius: 12px;
    padding: 28px;
    overflow: auto;
}
.null-button-next {
    display: none !important;
}
.wrapper-class {
    padding: 0 !important;
    margin-top: 16px
}
.step-class span{
    height: 40px !important;
    width: 40px !important;
}
.step-class span svg{
    height: 20px !important;
    width: 20px !important;
}
.label-class {
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #58606A;
}

._35Ago span{
    background-color: #215493 !important;
}
.height25 {
    height: 25% !important
}
.text-table {
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #1C1E21;
}
.tab-project {
    width: 100%;
    height: 42px;
    margin-top: 24px;
    justify-content: flex-start;
    align-items: flex-end;
    border-bottom: 1px solid #CDD1D5
}

.tab-project div{
    padding: 0px 20px 3px 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    color: #58606A;
    cursor: pointer;
}
.tab-active {
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    color: #215493 !important;
    border-bottom: 2px solid #215493;
}

.table-chart-project {
    width: 100%;
    height: 81px;
    justify-content: space-between;
    align-items: center;
}
.option-table-chart-project {
    height: 100%;
    justify-content: flex-start;
    align-items: center;
}

.chart-button {
    width: 140px;
    height: 42px;
    justify-content: center;
    align-items: center;
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: #58606A;
    cursor: pointer;
}

.table-button {
    width: 88px;
    height: 42px;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 26px;
    color: #58606A;
    cursor: pointer;
}

.active-chart {
    background: rgba(33, 84, 147, 0.1);
    border-radius: 6px;
    color: #215493 !important
}

.fix-height {
    /* height: calc(100% - 600px) !important; */
    height: 300px !important;
}

.close-button {
    width: 100%;
    height: 50px;
    margin-top: 20px;
}
.project-inf {
    height: 224px !important;
}

.search-select-style {
    width: 350px !important;
    height: 36px !important;
    margin-right: 16px;
    padding: 0px !important;
}

.search-date-style {
    width: auto;
}

.search-select-style .MuiOutlinedInput-root {
    height: 36px;
    padding: 0px !important;
    padding-left: 10px !important;
}

.search-select-style .MuiAutocomplete-root  {
    height: 36px;
    padding: 0px !important;
}

.search-select-style fieldset {
    height: 42px !important;
    border-radius: 8px;
}

.select-date-range {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.spread-select {
    margin: 0px 10px;
}

.search-free-style {
    width: 684px;
    height: 36px;
}

.search-free-style .MuiOutlinedInput-root {
    height: 36px;
    border-radius: 8px;
}

.search-free-style legend {
    /* display: none !important; */
}

.searchRow {
    margin-bottom: 24px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.button-create {
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: #215493;
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #215493;
    padding: 10px 18px;
    gap: 8px;
    width: 130px;
    height: 32px;
    border: 1px solid rgba(33, 84, 147, 0.4);
    filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
    border-radius: 6px;
}

.actionArea {
    width: 100%;
    display: flex;
    align-items: center;
    margin-left: auto;
    justify-content: flex-end;
}

.label {
    margin-bottom: 8px;
}

.datepicker-wrap {
    width: 100%;
}

.datepicker-wrap input {
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 12px;
    gap: 8px;
    width: 100%;
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #CDD1D5;
    border-radius: 8px;
}

.modal-update-workinglog {
    max-height: 90vh;
    overflow: auto;
}

/* .search-box-style .MuiAutocomplete-hasPopupIcon.css-viou3o-MuiAutocomplete-root .MuiOutlinedInput-root, .MuiAutocomplete-hasClearIcon.css-viou3o-MuiAutocomplete-root .MuiOutlinedInput-root  {
    height: 36px;
    padding: 0px;
} */

.search-box-style {
    width: 333px;
    height: 36px;
    padding: 0px;
    border-radius: 8px;
}