.modal-news-detail {
    padding: 8px;
    /* gap: 20px; */
    width: 700px;
    max-height: 90vh;
    background: #FFFFFF;
    border-radius: 12px;
    overflow-y: scroll;
}

.modal-news-detail::-webkit-scrollbar {
    display: none;
}

  /* Hide scrollbar for IE, Edge and Firefox */
.modal-news-detail {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.modal-news-detail-title {
    justify-content: flex-start;
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
}

.modal-news-title {
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #448CDF;
    margin: 16px 0;
}

.modal-news-content {
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.7);
}

.modal-cancel-news {
    justify-content: center;
    align-items: center;
    padding: 4px 16px;
    width: 130px;
    height: 32px;
    background: #D2D4D7;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
}
.btn-schedule {
    background-color: #215493;
    border-radius: 8px;
    padding: 10px 18px;
    color: #fff;
}
.btn-schedule:disabled, .btn-schedule[disabled] {
    opacity: 0.7;
}
