.input-area {
    border-radius: 8px !important;
}

.workinglog-dropdown .ant-select-selector {
    border-radius: 8px !important;
    padding: 3px 12px !important;
    height: unset !important;
}

.workinglog-create .ant-modal-header {
    border-radius: 12px !important;
}

.workinglog-create .ant-modal-content {
    border-radius: 12px !important;
}

.custom-dropdown .MuiInputBase-root  {
    padding-left: 0px !important;
    padding: 0px !important;
    border-radius: 8px;
    border: 1px solid #CDD1D5 !important;
    padding-left: 10px !important;
}
.custom-dropdown input {
    padding-left: 10px !important;
}
.custom-dropdown .input-select-style {
    max-width: 100% !important;
}
.button-plus-wrp {
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #215493;
    padding-left: 5px;
}
