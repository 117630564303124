.container-worker {
  width: 100%;
  background-color: #e3e6e8;
  padding: 28px;
}

.worker-content {
  min-height: calc(100% - 56px);
  width: 100%;
  background: #ffffff;
  border-radius: 12px;
  padding: 28px;
}
.table-workers {
  width: 100%;
}
.table-budget thead .ant-table-cell {
  font-weight: 700;
}
.table-budget td,
th {
  text-align: center !important;
}

.search-workers {
  width: 100%;
  height: 150px;
  justify-content: flex-start;
  align-items: flex-start;
  /* overflow-y: scroll; */
  margin-bottom: 15px;
}
.search-workers-row {
  width: 100%;
  height: 50%;
}
.input-add-user {
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  max-width: 270px;
}
.input-add-user input {
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 12px;
  gap: 8px;
  width: 100%;
  height: 36px;
  background: #ffffff;
  border: 1px solid #cdd1d5;
  border-radius: 8px;
}

.search-workers-row-dropdown {
  width: 33.33%;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
}

.dropdown-create-user1 {
  width: calc(100%);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;
  height: 36px;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  /* font-family: 'Zen Kaku Gothic Antique'; */
}
/* .Dropdown-placeholder {
    font-weight: normal;
    color: #000
} */

.margin-left {
  margin-left: 28px;
}

.search-workers-row-button {
  width: 33.33%;
  height: 100%;
  justify-content: flex-end;
  align-items: flex-end;
}
.text-overflow1 {
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  max-width: 250px;
  /* height: 56px; */
}

@media only screen and (max-width: 1450px) {
  .text-overflow1 {
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    max-width: 150px;
  }
}

.input-add-user .select-search {
  width: 100% !important;
}

.input-add-user .select-search__input {
  width: 100% !important;
}
.select-search__select {
  background-color: #ffffff;
  z-index: 100;
}
