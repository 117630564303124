.table-company {
    height: calc(100% - 130px)
}

.search-client-company-button {
    width: 100%;
    justify-content: flex-start;
    height: 70px;
    align-items: end;
}

.search-client-company-row-button {
    height: 100%;
    justify-content: flex-end;
    align-items: flex-start;
    margin-top: 1px;
}
