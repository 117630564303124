.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye {
    width: 30px !important;
    height: 30px !important;
  }
  
  .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye svg {
    width: 30px !important;
    height: 30px !important;
  }
  
  .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
    width: 30px !important;
    height: 30px !important;
  
    svg {
      width: 30px !important;
      height: 30px !important
    }
  }
  
  .ant-upload-list-picture-card .ant-upload-list-item-actions {
    left: 45% !important
  }

  .ant-upload-list-picture-card .ant-upload-list-item-info::before {
    left: 0 !important
  }