.container-worker-detail {
    /* width: calc(100% - 56px);
    height: calc(100% - 120px); */
    width: 100%;
    height: 100%;
    background-color: #E3E6E8;
    padding: 28px;
    /* overflow-y: hidden; */
}

.worker-detail-content {
    /* width: 944px;
    height: 518px; */
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    border-radius: 12px;
    padding: 28px;
    /* overflow: auto; */
}

.worker-detail-name-title{
    width: 100%;
    height: 54px;
    justify-content: flex-start;
    align-items: flex-start;
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    color: #1C1E21;
}
.worker-detail-inf {
    width: 100%;
    height: 560px;
    /* background-color:#E3E6E8; */
    border: 1px solid #CDD1D5;
    border-radius: 8px;
}

.worker-detail-inf-row-title {
    width: 22.5225%;
    height: 100%;
    background: #C7DCF5;
    border-bottom: 1px solid #F5F5F5;
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #1C1E21;
    padding-left: 12px
}
.worker-detail-inf-row-content {
    width: 77.477%;
    height: 100%;
    border-bottom: 1px solid #F5F5F5;
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #1C1E21;
    padding-left: 12px
}
.worker-detail-inf-row {
    width: 100%;
    height: 56px !important;
}

.worker-detail-footer {
    width: 100%;
    height: 70px;
    justify-content: flex-end;
    align-items: flex-end;
}   

.back-button {
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    padding: 10px 18px;
    gap: 8px;
    width: 167.5px;
    height: 42px;
    border: 1px solid rgba(33, 84, 147, 0.3);
    filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
    border-radius: 8px;
}

.client-company-header {
    justify-content: space-between;
    align-items: center;
    margin-bottom: 21px;
}

.client-company-bottom {
    justify-content: flex-start !important;
    align-items: center;
    margin-top: 21px;
}