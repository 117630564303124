.container-worker-detail-f {
    width: 100%;
    height: auto;
    background-color: #E3E6E8;
    padding: 28px;
    /* overflow-y: hidden; */
}

.worker-detail-content-f {
    width: 100%;
    height: auto;
    background: #FFFFFF;
    border-radius: 12px;
    padding: 28px;
    /* overflow: hidden; */
}

.worker-detail-name-title{
    width: 100%;
    height: 54px;
    justify-content: flex-start;
    align-items: flex-start;
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    color: #1C1E21;
}
.worker-detail-inf-f {
    width: 100%;
    /* height: calc(100% - 54px - 70px); */
    /* background-color:#E3E6E8; */
    border: 1px solid #CDD1D5;
    border-radius: 8px;
}

.worker-detail-inf-row-f-title {
    width: 100px;
    height: 100%;
    background: #C7DCF5;
    border-bottom: 1px solid #F5F5F5;
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #1C1E21;
    padding-left: 12px
}
.worker-detail-inf-row-f-content {
    width: calc(100% - 100px);
    height: 100%;
    border-bottom: 1px solid #F5F5F5;
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #1C1E21;
    padding-left: 12px
}
.worker-detail-inf-row-f {
    width: 100%;
    height: 56px !important;
}

.worker-detail-inf-row-f-3 {
    width: 100%;
    height: 168px !important;
}

.worker-detail-footer-f {
    width: 100%;
    height: 70px;
    justify-content: flex-start;
    align-items: flex-end;
}   

.back-button {
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    padding: 10px 18px;
    gap: 8px;
    width: 167.5px;
    height: 42px;
    border: 1px solid rgba(33, 84, 147, 0.3);
    filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
    border-radius: 8px;
}

.bg-w {
    background-color: #FFFFFF !important;
}