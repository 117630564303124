.header {
    width: 100%;
    height: 64px;
    margin: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
}

.header-left {
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #1c1e21;
    padding: 16px 28px;
}

.header-right {
    height: 100%;
    justify-content: flex-end;
    align-items: center;
    padding: 0px 0px 0px 21px;
}

.line {
    width: 1px;
    height: 30px;
    background-color: #cdd1d5;
    margin: 0px 0px 0px 24px;
}

.username {
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: #1c1e21;
    padding-left: 21px;
    padding-right: 21px;
    align-self: stretch;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.MuiMenuItem-root {
    font-family: 'Zen Kaku Gothic Antique' !important;
}

@media only screen and (max-width: 992px) {
    .header {
        display: none;
    }
}
