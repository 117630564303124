@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.table-content.d-flex > div {
    box-shadow: rgb(0 0 0 / 2%) 0px 2px 1px -1px,
        rgb(0 0 0 / 10%) 0px 1px 1px 0px, rgb(0 0 0 / 13%) 0px 1px 3px 0px !important;
}

/* .w-full.flex>div {
    box-shadow: rgb(0 0 0 / 2%) 0px 2px 1px -1px, rgb(0 0 0 / 10%) 0px 1px 1px 0px, rgb(0 0 0 / 13%) 0px 1px 3px 0px !important
} */

.tb-ct.d-flex > div {
    box-shadow: rgb(0 0 0 / 2%) 0px 2px 1px -1px,
        rgb(0 0 0 / 10%) 0px 1px 1px 0px, rgb(0 0 0 / 13%) 0px 1px 3px 0px !important;
}
