/* .jexcel {
  font-family: "Roboto";
} */
.jexcel_row {
    background: #c7dcf5 !important;
}
thead tr td {
    background: #c7dcf5 !important;
}
.wrap-excel {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: end;
}
.summary {
    width: 271px;
    background-color: #e7e7e7;
    border: 1px solid #bbbaba;
}
.jexcel > thead > tr > td {
    text-align: center !important;
}
.jexcel > tbody > tr > td {
    padding-left: 8px;
    padding-right: 8px;
}
.jexcel > tbody > tr > td.child-name {
    padding-left: 38px;
}
.jexcel > tbody > tr > td.color-unset {
    color: unset;
}
.jexcel > tbody > tr > td:nth-child(7),
.jexcel > tbody > tr > td:nth-child(12),
.jexcel > tbody > tr > td:nth-child(13),
.jexcel > tbody > tr > td:nth-child(14) {
    font-weight: 700;
}

.coating {
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: black;
    opacity: 0.1;
    top: 0;
    left: 0;
}

.compare-loading {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.button-export {
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: #215493;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: #215493;
    padding: 10px 18px;
    gap: 5px;
    width: 108px;
    height: 38px;
    border: 1px solid rgba(33, 84, 147, 0.4);
    filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
    border-radius: 6px;
    background: #ffff;
}
