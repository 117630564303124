.ant-space-item {
    span {
        margin: 0px 4px !important;
    }
}

.success:focus {
    border-color: none !important;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(87,168,233, .2);
    box-shadow: 0 0 0 2px rgba(87,168,233, .2);
}

// .ant-input-affix-wrapper.ant-input-password {
//     border: none;
//   }

  .ant-input-status-success {
    border: 1px solid #D9D9D9;
    box-shadow: none !important;
  }

  .ant-input-status-success:hover {
    border: 1px solid #D9D9D9 !important;
    box-shadow: none !important;
  }
  
  .ant-input-status-success:focus {
    border: 1px solid #D9D9D9 !important;
    box-shadow: none !important;
  }
//   .ant-input-affix-wrapper-focused {
//     box-shadow: none !important;
//   }

  .ant-input:hover {
    border: 1px solid #D9D9D9 !important;
    box-shadow: none !important;
  }