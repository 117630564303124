@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100&family=Zen+Kaku+Gothic+Antique:wght@500&display=swap');
.container-login {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background: #ffffff;
}

.login-background {
    width: 57.5%;
    height: 100%;
}

.login-form-mobile {
    background: #ffffff;
    border-radius: 12px;
    display: none;
}

@media only screen and (max-width: 992px) {
    .login-form-mobile {
        display: flex;
    }
    .login-background {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-size: cover;
    }
    .right {
        display: none !important;
        width: 100% !important;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .login-form {
        padding: 40px 16px;
        max-width: 343px;
        width: calc(100vw - 32px);
        align-items: center !important;
    }
    .login-form > .title {
        font-family: 'Zen Kaku Gothic Antique';
        font-weight: 700;
        font-size: 24px;
        line-height: 44px;
        color: #1c1e21;
    }
    .login-form > .form {
        align-items: center !important;
    }
    .login-form > .form > .checkbox {
        align-self: flex-start;
    }
}

.login-background-img {
    width: 100%;
    height: 100%;
}

.login-form {
    justify-content: center;
    align-items: flex-start;
}

.title {
    /* position: absolute; */
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 44px;
    color: #1c1e21;
    margin-bottom: 40px;
}

.form {
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.form .text-field {
    padding-bottom: 12px;
    width: 100%;
    display: flex;
    flex-direction: column !important;
}

.validate-warning {
    margin: 0;
    padding: 0;
    color: #f70000;
    font-size: 14px;
    font-family: 'Zen Kaku Gothic Antique';
}

.form div .MuiOutlinedInput-root {
    margin-top: 4px;
    height: 44px;
    width: 100%;
    background: #ffffff;
    border: 1px solid #cdd1d5;
    border-radius: 8px;
    font-size: 14px;
}

.form div .MuiOutlinedInput-root input {
    padding: 10.5px 14px !important;
    font-size: 14px;
}

.form div label {
    font-family: 'Zen Kaku Gothic Antique';
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
}

.form label {
    font-family: 'Zen Kaku Gothic Antique';
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
    margin-top: 8px;
}

.checkbox {
    justify-content: flex-start;
    align-items: flex-start;
}

.checkbox-text {
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    color: #1c1e21;
    margin-top: 8px;
}

.submit-button {
    justify-content: center;
    align-items: center;
    padding: 10px 18px;
    gap: 8px;
    width: 150px;
    height: 44px;
    background: #215493;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-color: #215493;
    border-radius: 8px;
    font-family: 'Zen Kaku Gothic Antique';
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    border: none;
    margin-top: 28px;
}

.submit-button:hover {
    background-color: #33437d;
}

fieldset {
    border: none !important;
}