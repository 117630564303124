.container-users {
    /* width: calc(100% - 56px);
    height: calc(100% - 120px); */
    width: 100%;
    height: 100%;
    background-color: #e3e6e8;
    padding: 28px;
    overflow: hidden;
}

.users-content {
    /* width: calc(100% - 56px);
    height: calc(100% - 56px); */
    width: 100%;
    height: 100%;
    background: #ffffff;
    border-radius: 12px;
    padding: 28px;
}

.create-button {
    width: 100%;
    /* height: 8%; */
    justify-content: flex-end;
}

.search-user {
    width: 100%;
    height: 12%;
    min-height: 70px;
}

.table-user {
    width: 100%;
    height: 80%;
    overflow: auto;
    min-height: 100px;
}

/* .table-user:hover {
    overflow-y: scroll;
  } */

.create-user-button {
    justify-content: center;
    align-items: center;
    border-color: #215493;
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #215493;
    padding: 10px 18px;
    gap: 8px;
    /* position: absolute; */
    width: 130px;
    height: 36px;
    border: 1px solid rgba(33, 84, 147, 0.4);
    filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
    border-radius: 6px;
}

.search-user-button {
    justify-content: center;
    align-items: center;
    padding: 10px 18px;
    gap: 8px;
    width: 150px;
    height: 44px;
    background: #215493;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-color: #215493;
    border-radius: 8px;
    font-family: 'Zen Kaku Gothic Antique';
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    border: none;
}

.create-user-button:hover {
    background-color: #c7dcf5;
}

.input-search {
    justify-content: flex-start;
    align-items: flex-start;
    width: 50%;
    /* max-width: 300px; */
}

.input-search2 {
    justify-content: flex-start;
    align-items: flex-start;
    width: 40%;
    /* max-width: 300px; */
}

.input-search2 p {
    padding-right: 20px;
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
    margin: 0;
    padding-bottom: 4px;
}

.input-search2 input {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 12px;
    width: calc(100% - 56px);
    min-width: 150px;
    height: 36px;
    background: #ffffff;
    border: 1px solid #cdd1d5;
    border-radius: 8px;
}

.input-search p {
    padding-right: 20px;
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
    margin: 0;
    padding-bottom: 4px;
}

/* .input-search input {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 12px;
    width: calc(100% - 56px);
    min-width: 150px;
    height: 36px;
    background: #FFFFFF;
    border: 1px solid #CDD1D5;
    border-radius: 8px;
} */

.table-title {
    width: 100%;
    border-bottom: 1px solid #575757;
    height: 50px;
    justify-content: space-between;
}

.table-column {
    width: 50%;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.table-left {
    width: 50%;
    height: 100%;
    justify-content: space-around;
    align-items: center;
}

.table-right {
    width: 50%;
    height: 100%;
    justify-content: flex-end;
    padding-right: 120px;
}

.text-black {
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #1c1e21;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.table-item {
    width: 100%;
    height: 50px;
}

.table-right-bt {
    width: 50%;
    height: 100%;
    justify-content: flex-end;
    margin-right: 30px;
    margin-left: 90px;
}

.edit-user-button {
    justify-content: center;
    align-items: center;
    padding: 10px 18px;
    gap: 8px;
    width: 100px;
    height: 36px;
    background: #d1cfcf;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-color: #d1cfcf;
    border-radius: 8px;
    font-family: 'Zen Kaku Gothic Antique';
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #1c1e21;
    border: none;
    margin-left: 10px;
}

.edit-user-button :hover {
    background-color: #33437d;
}

.modal-create-user {
    width: 399px;
    min-height: 152px;
    background: #ffffff;
    border-radius: 12px;
    padding: 28px;
    justify-content: center;
    align-items: center;
}
.modal-create-user-title {
    width: 100%;
    height: 10%;
    justify-content: center;
    align-items: center;
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #1c1e21;
}
.modal-create-user-form {
    width: 100%;
    height: 70%;
    justify-content: flex-start;
    align-items: center;
}

.modal-create-user-buttons {
    width: 100%;
    height: 20%;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}
.input-add-user {
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
}
.input-add-user input {
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 12px;
    gap: 8px;
    width: 100%;
    height: 36px;
    background: #ffffff;
    border: 1px solid #cdd1d5;
    border-radius: 8px;
}

.input-add-user textarea {
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 12px;
    gap: 8px;
    width: 100%;
    height: 84px;
    background: #ffffff;
    border: 1px solid #cdd1d5;
    border-radius: 8px;
}

.input-add-user p {
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
    margin: 0;
    margin-bottom: 4px;
    margin-top: 12px;
}
.dropdown-create-user {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    height: 38px;
    background: #ffffff;
}
.dropdown-control-user .Dropdown-placeholder {
    color: rgba(0, 0, 0, 0.87) !important;
}
.Dropdown-option {
    color: rgba(0, 0, 0, 0.87) !important;
}
.dropdown-control-user {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    height: 38px;
    background: #ffffff;
    border: 1px solid #cdd1d5 !important;
    border-radius: 8px !important;
}

.modal-create-user-button2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 18px;
    gap: 8px;
    width: 49%;
    height: 44px;
    background: #215493;
    /* box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05); */
    border-radius: 8px;
    outline: none;
    border-color: #215493;
    color: #ffffff;
    outline: none;
    border: none;
}
.modal-create-user-button1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 18px;
    gap: 8px;
    width: 49%;
    height: 44px;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border: 1px solid rgba(33, 84, 147, 0.3);
    filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
    border-radius: 8px;
    outline: none;
    border-color: #215493;
    color: #215493;
}

.width190 {
    width: 190px !important;
}

.icon-error-mss {
    background: red;
    margin-right: 10px;
    padding: 2px;
    svg {
        color: white !important;
    }
}
.icon-error-mss svg {
    color: white !important;
}
