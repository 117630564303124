.chart-control-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}
.chart-control-button-pre {
    margin-right: 16px !important
}
.chart-control-button-next {
    margin-left: 16px !important
}
.chart-text-month, .chart-text-year {
    padding: 0 20px 0 8px
}
.highcharts-credits {
    display: none !important;
}